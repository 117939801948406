import { Model } from "@vuex-orm/core";
import { formatISO9075, parseISO } from "date-fns";
import { getDropdownOptionsFromObjectArray } from "@/utils/dropdown";
import { isEntityContainsAllTerms } from "@/utils/filters";

export default class CustomerNotification extends Model {
  static entity = "customerNotification";

  static fields() {
    return {
      id: this.attr("00000000-0000-0000-0000-000000000000"),
      subject: this.attr(null),
      semanticVersion: this.attr(null),
      publishDate: this.attr(null),
      content: this.attr({}),
      contentType: this.attr(null),
      useDefaultLanguage: this.attr(true),
    };
  }

  isValidForLocale(locale) {
    return this.IsValid && !!this.content[locale];
  }

  get EntityIdentifier() {
    return "CustomerNotification";
  }

  get IsValid() {
    return (
      this.semanticVersion !== null &&
      this.semanticVersion !== "" &&
      this.content !== {} &&
      this.publishDate !== null
    );
  }

  get PublishDate() {
    return this.publishDate ? parseISO(this.publishDate) : null;
  }

  set PublishDate(value) {
    this.publishDate = formatISO9075(value);
  }

  static get() {
    return this.api().get("/customer-notification", { dataKey: "result" });
  }

  /**
   * Returns the customer notification following the one with the provided semantic version.
   * @param notificationVersion
   */
  static async getNextFromVersion(notificationVersion) {
    return this.api().get(
      `/customer-notification/next/${notificationVersion}`,
      {
        dataTransformer: (response) => {
          return response && response.data
            ? response.data.result
            : new CustomerNotification();
        },
      }
    );
  }

  static async post(newCustomerNotification) {
    return this.api().post("/customer-notification", newCustomerNotification, {
      dataKey: "result",
    });
  }

  static async remove(customerNotification) {
    return this.api().delete(
      "/customer-notification/" + customerNotification.id,
      {
        delete: customerNotification.id,
        dataKey: "result",
      }
    );
  }

  static async put(updatedCustomerNotification) {
    return this.api().put(
      `/customer-notification/${updatedCustomerNotification.id}`,
      updatedCustomerNotification,
      {
        dataKey: "result",
      }
    );
  }

  static getContentTypeList() {
    const entriesTranslated = Object.values(contentTypes).map((id) => ({
      id: id,
      name: id,
    }));
    return getDropdownOptionsFromObjectArray(entriesTranslated);
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    return [this.id, this.semanticVersion, this.subject];
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}

export const contentTypes = ["text/markdown"];
