<template>
  <div class="text-h3 font-sans truncate me-6 flex">
    <div
      :key="index"
      v-for="(crumb, index) in crumbs"
      class="items-center flex-shrink-0"
    >
      <span v-if="index < crumbs.length - 1" class="flex items-center">
        <span v-if="navigationDisabled(crumb)">{{ crumb.text }}</span>
        <router-link
          v-else
          :to="crumb.to"
          :append="true"
          #default="{ navigate, href }"
          custom
        >
          <a :href="href" v-on:click.prevent="navigate(href)">
            {{ crumb.text }}
          </a>
        </router-link>
        <svg-icon name="chevron-right" class="icon-md mx-3 flip-icon-if-rtl" />
      </span>
      <span v-else>{{ crumb.text }}</span>
    </div>
    <new-feature-badge v-if="showNewBadge" class="ms-4" />
  </div>
</template>

<script>
import NewFeatureBadge from "@/components/ui/NewFeatureBadge.vue";
import { getSessionStorageDisableSideBar } from "@/store/sessionStorage.js";

export default {
  components: { NewFeatureBadge },
  data() {
    return {
      disableSideBar: getSessionStorageDisableSideBar(),
    };
  },
  props: {
    /**
     * Crumbs will be displayed in order, separated with " > "
     * [
     *  {
     *    to: "path_to_navigate_to",
     *    text: "displayed text"
     *  },
     *  {
     *    to: "path_to_navigate_to",
     *    text: "displayed text"
     *  }
     * ]
     */
    crumbs: {
      type: Array,
      required: true,
    },
    showNewBadge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    navigationDisabled() {
      return (crumb) => !crumb?.to || this.disableSideBar;
    },
  },
};
</script>
