import { Model } from "@vuex-orm/core";
import Customer from "@/models/Customer.model";
import Asset from "@/models/Asset.model";
import { parseISO, formatISO9075 } from "date-fns";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";
import { tr } from "@/utils/translation";

export default class MindPodLicense extends Model {
  static entity = "mindPodLicenses";

  static fields() {
    return {
      id: this.attr(null),
      addedAtUtc: this.attr(null),
      version: this.attr(null),
      sfdcLicenseID: this.attr(null),
      customerId: this.attr(null),
      assetId: this.attr(null),
      validUntil: this.attr(null),
      isActive: this.attr(false),
      customer: this.belongsTo(Customer, "customerId"),
      asset: this.belongsTo(Asset, "assetId"),
    };
  }

  get EntityIdentifier() {
    return "MindPodLicense";
  }

  get LicenseTypeForDisplayAndSearch() {
    return tr(`AdminPages.${this.EntityIdentifier}.licenseType`);
  }

  set Customer(value) {
    this.customer = value;
    this.customerId = value.id;
  }

  get Customer() {
    return this.customer;
  }

  set Asset(value) {
    this.asset = value;
    this.assetId = value.id;
  }

  get Asset() {
    return this.asset;
  }

  get CustomerFullHtmlName() {
    return this.customer?.fullName?.length > 0
      ? this.customer.fullNameHtml
      : "";
  }

  get IsCustomerValid() {
    return !!this.customer;
  }

  get ValidUntil() {
    return this.validUntil ? parseISO(this.validUntil) : null;
  }
  set ValidUntil(value) {
    this.validUntil = value ? formatISO9075(value) : null;
  }

  get AddedAtUtc() {
    return this.addedAtUtc ? parseISO(this.addedAtUtc) : null;
  }
  set AddedAtUtc(value) {
    this.addedAtUtc = value ? formatISO9075(value) : null;
  }

  static get() {
    return this.api().get("/mindpod-license", { dataKey: "result" });
  }

  static async post(newLicense) {
    return this.api().post("/mindpod-license", newLicense, {
      dataKey: "result",
    });
  }

  static async remove(license) {
    return this.api().delete("/mindpod-license/" + license.id, {
      delete: license.id,
      dataKey: "result",
    });
  }

  static async put(updatedLicense) {
    // Let's strip the customer data
    let updatedLicenseCopy = JSON.parse(JSON.stringify(updatedLicense));
    updatedLicenseCopy.customer = null;
    return this.api().put(
      "/mindpod-license/" + updatedLicenseCopy.id,
      updatedLicenseCopy,
      {
        dataKey: "result",
      }
    );
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    let fields = [
      this.sfdcLicenseID,
      this.id,
      this.LicenseTypeForDisplayAndSearch,
    ];
    if (this.customer?.fullName) {
      fields.push(tidySearchTerm(this.customer.fullName));
    }
    return fields;
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}
