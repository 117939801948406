import customerRoutes from "@/router/entities/customer";
import siteRoutes from "@/router/entities/site";
import siteManagementRoutes from "@/router/entities/siteManagement";
import licenseRoutes from "@/router/entities/license";
import patientLicenseRoutes from "@/router/entities/patientLicense";
import mindPodLicenseRoutes from "@/router/entities/mindPodLicense";
import userSessionRoutes from "@/router/entities/user-session";
import assetRoutes from "@/router/entities/asset";
import administratorRoutes from "@/router/entities/administrator";
import dpoRoutes from "@/router/entities/dpoadmin";
import therapistRoutes from "@/router/entities/therapist";
import patientRoutes from "@/router/entities/patient";
import siteManagerRoutes from "@/router/entities/siteManager";
import customerNotificationRoutes from "@/router/entities/customerNotification";
import dataExportRoutes from "@/router/dataExportRoutes";
import optionalFeatureDefinitionRoutes from "@/router/entities/optionalFeatureDefinition";
import optionalFeatureActivationRoutes from "@/router/entities/optionalFeatureActivation";
import feedbackReportRoutes from "@/router/entities/feedbackReport";
import archivedUsersRoutes from "@/router/entities/archivedUsers";
import { getSupportRoutes } from "@/router/supportRoutes";
import { getProfileRoutes } from "@/router/profileRoutes";

const routePrefix = "/superadmin";

export default [
  {
    path: routePrefix,
    component: () => import("@/pages/AdminPages/SuperAdminHomePage"),
    meta: {
      requiresSuperAdmin: true,
    },
    children: [
      ...getSupportRoutes(routePrefix),
      ...getProfileRoutes(routePrefix),
      ...customerRoutes,
      ...siteRoutes,
      ...siteManagementRoutes,
      ...licenseRoutes,
      ...patientLicenseRoutes,
      ...mindPodLicenseRoutes,
      ...assetRoutes,
      ...userSessionRoutes,
      ...administratorRoutes,
      ...dpoRoutes,
      ...therapistRoutes,
      ...patientRoutes,
      ...siteManagerRoutes,
      ...customerNotificationRoutes,
      ...dataExportRoutes,
      ...optionalFeatureDefinitionRoutes,
      ...optionalFeatureActivationRoutes,
      ...feedbackReportRoutes,
      ...archivedUsersRoutes,
      {
        path: "emails",
        name: "emailTest",
        component: () =>
          import("@/pages/AdminPages/SuperAdminHomePage/EmailTestPage.vue"),
      },
      {
        path: "activity-definition",
        name: "activityDefinition",
        component: () =>
          import(
            "@/pages/ReferencePages/ActivityDefinitionPage/ActivityDefinitionPage.vue"
          ),
      },
      {
        path: "",
        name: "SuperAdmin/Home",
        component: () =>
          import("@/pages/AdminPages/SuperAdminHomePage/Dashboard"),
      },
    ],
  },
];
