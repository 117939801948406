import { Model } from "@vuex-orm/core";
import Customer from "@/models/Customer.model";
import { formatISO9075, parseISO } from "date-fns";
import { getDropdownEntries } from "@/utils/dropdown";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";

export const assetKinds = Object.freeze({
  MMGO: "MMGO",
  MindPod: "MindPod",
});

export const assetMode = Object.freeze({
  Public: "Public",
  Private: "Private",
});

export default class Asset extends Model {
  static entity = "assets";

  static fields() {
    return {
      id: this.attr(null),
      kind: this.attr(assetKinds.MMGO),
      deviceMode: this.attr(assetMode.Public),
      addedAtUtc: this.attr(null),
      version: this.attr(null),
      sfdcAssetId: this.attr(null),
      customerId: this.attr(null),
      deviceId: this.attr(null),
      serialNumber: this.attr(null),
      customer: this.belongsTo(Customer, "customerId"),
    };
  }

  static beforeCreate(asset) {
    if (asset.kind === assetKinds.MindPod) {
      asset.deviceMode = assetMode.Public;
    }
  }

  static beforeUpdate(asset) {
    if (asset.kind === assetKinds.MindPod) {
      asset.deviceMode = assetMode.Public;
    }
  }

  get EntityIdentifier() {
    return "Asset";
  }

  get AddedAtUtc() {
    return this.addedAtUtc ? parseISO(this.addedAtUtc) : null;
  }
  set AddedAtUtc(value) {
    this.addedAtUtc = formatISO9075(value);
  }

  set Customer(value) {
    this.customer = value;
    this.customerId = value.id;
  }

  get Customer() {
    return this.customer;
  }

  static get() {
    return this.api().get("/asset", { dataKey: "result" });
  }

  static async post(newAsset) {
    return this.api().post("/asset", newAsset, { dataKey: "result" });
  }

  static async remove(asset) {
    return this.api().delete("/asset/" + asset.id, {
      delete: asset.id,
      dataKey: "result",
    });
  }

  static async put(updatedAsset) {
    // Let's strip the customer data
    var updatedAssetCopy = JSON.parse(JSON.stringify(updatedAsset));
    updatedAssetCopy.customer = null;
    return this.api().put("/asset/" + updatedAssetCopy.id, updatedAssetCopy, {
      dataKey: "result",
    });
  }

  static getAssetModeList() {
    return getDropdownEntries(assetMode, "AdminPages.Asset.Mode");
  }

  static getAssetKindList() {
    return getDropdownEntries(assetKinds, null);
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    let fields = [this.id, this.deviceId, this.serialNumber, this.sfdcAssetId];
    if (this.customer?.fullName) {
      fields.push(tidySearchTerm(this.customer.fullName));
    }

    return fields;
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    if (!this.entityFieldsToSearchInto.length) {
      return false;
    }
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}

export const searchAssetFilter = (assets, searchTerms) => {
  return assets.filter((element) =>
    element.isEntityCorrespondingTo(searchTerms)
  );
};
