import { getSupportRoutes } from "@/router/supportRoutes";
import { getProfileRoutes } from "@/router/profileRoutes";
import siteUnitRoutes from "@/router/entities/siteUnit";

const routePrefix = "/site-manager";

export default [
  {
    path: "/",
    redirect: "therapists",
    name: "SiteManager/Home",
    component: () => import("@/pages/SiteManagerPages/SiteManagerHomePage"),
    meta: {
      requiresSiteManager: true,
    },
    children: [
      ...getSupportRoutes(routePrefix),
      ...getProfileRoutes(routePrefix),
      ...siteUnitRoutes,
      {
        path: "therapists",
        name: "SiteManager/Therapists",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/UserListPage"),
        props: {
          "user-type": "therapist",
        },
      },
      {
        path: "therapists/new",
        name: "SiteManager/CreateTherapist",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/AddUser"),
        props: (route) => ({
          "user-type": "therapist",
          siteUnitId: route.params.siteUnitId,
        }),
      },
      {
        path: "therapists/:username",
        name: "SiteManager/EditTherapist",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
        props: (route) => ({
          username: route.params.username,
        }),
      },
      {
        path: "site-managers",
        name: "SiteManager/SiteManagers",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/UserListPage"),
        props: {
          "user-type": "site",
        },
      },
      {
        path: "site-managers/new",
        name: "SiteManager/CreateSiteManager",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/AddUser"),
        props: {
          "user-type": "site",
        },
      },
      {
        path: "site-managers/:username",
        name: "SiteManager/EditSiteManager",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
        props: (route) => ({
          username: route.params.username,
        }),
      },
      {
        path: "archived-users",
        name: "SiteManager/ArchivedUsers",
        component: () =>
          import(
            "@/pages/SiteManagerPages/SiteManagerHomePage/ArchivedUsersPage.vue"
          ),
      },
      {
        path: "archived-users/:username",
        name: "SiteManager/EditArchivedUser",
        component: () =>
          import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
        props: (route) => ({
          username: route.params.username,
        }),
      },
      {
        path: "license",
        name: "SiteManager/License",
        component: () =>
          import(
            "@/pages/SiteManagerPages/SiteManagerHomePage/LicenseOverview"
          ),
      },
      {
        path: "site-parameters",
        name: "SiteManager/SiteManagement/SiteParameters",
        component: () => import("@/pages/SiteManagerPages/SiteParameters"),
      },
      {
        path: "personal-access-token",
        name: "SiteManager/SiteManagement/PersonalAccessToken",
        component: () => import("@/pages/SiteManagerPages/PersonalAccessToken"),
      },
      {
        path: "personal-access-token/new",
        name: "SiteManager/SiteManagement/CreatePersonalAccessToken",
        component: () =>
          import(
            "@/pages/SiteManagerPages/PersonalAccessToken/AddPersonalAccessToken"
          ),
      },
    ],
  },
];
