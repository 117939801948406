// Service to fetch the dist/config.json file state
// This file is used to store environment specific data
// It is also overridden by the Companion.WebApp web server .Net project.

import axios from "axios";
import { generateUUID } from "@/utils/profile";

const environmentConfig = {
  fetchedDate: null,
  AppInsightInstrumentationKey: "",
  BuildVersion: "",
  ProductVersion: "",
  ProductDate: "",
  Regions: [],
  NewBadgeExpirationDate: "",
};

async function loadConfigData() {
  if (!environmentConfig.fetchedDate) {
    return await axios
      .get("/config.json?v=" + generateUUID())
      .then((response) => {
        const configData = response?.data || {};

        const setValue = (configKey) => {
          if (configData[configKey]) {
            environmentConfig[configKey] = configData[configKey];
          }
        };

        // Attempt to set each key if provided
        for (const key of Object.keys(environmentConfig)) {
          setValue(key);
        }

        // Memoize it
        environmentConfig.fetchedDate = new Date();
        return environmentConfig;
      })
      .catch(() => {});
  } else {
    return environmentConfig;
  }
}

export default loadConfigData;
