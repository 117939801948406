import { Model } from "@vuex-orm/core";
import { formatISO9075, parseISO } from "date-fns";
import Profile from "@/models/Profile.model";
import { getDropdownEntries } from "@/utils/dropdown";
import { addDays } from "date-fns";

export const auditLogCategory = Object.freeze({
  All: "all",
  SignedDocuments: "signedDocuments",
  Emails: "emails",
});

export default class AuditLog extends Model {
  static entity = "auditLogs";

  static fields() {
    return {
      id: this.attr(null),
      timestamp: this.attr(null),
      subjectUsername: this.attr(null),
      action: this.attr(null),
      objectType: this.attr(null),
      objectUsernames: this.attr([]),
      objectDetail: this.attr(null),
      detail: this.attr(null),
      subjectUser: this.belongsTo(Profile, "subjectUsername", "userName"),
      targetUsers: this.hasManyBy(Profile, "objectUsernames", "userName"),
    };
  }

  get EntityIdentifier() {
    return "AuditLog";
  }

  /**
   * Fetches the audit log data for the given date range
   * @param from {Date}
   * @param to {Date}
   * @return {Promise<AuditLog[]>}
   */
  static async get(from, to) {
    if (!(from instanceof Date) || !(to instanceof Date)) {
      // Prevents an error on loading with empty dates
      from = new Date();
      to = new Date();
    }
    const fromDate = formatISO9075(from, { representation: "date" });
    const toDate = formatISO9075(addDays(to, 1), { representation: "date" }); // add 1 day to be compliant with the backend behavior

    return this.api().get(`/audit-log?fromDate=${fromDate}&toDate=${toDate}`, {
      dataKey: "result",
    });
  }

  get Timestamp() {
    return this.timestamp ? parseISO(this.timestamp) : null;
  }

  static getAuditLogCategoryList() {
    return getDropdownEntries(auditLogCategory, "AdminPages.AuditLog.Category");
  }
}
