import ProgramNote from "@/models/ProgramNote.model";
import ProgramActivity from "@/models/ProgramActivity.model";
import { getAdaptiveTranslatedTimeFromSeconds } from "@/utils/duration";
import { generateUUID } from "@/utils/profile";
import ActivityDescription from "@/models/ActivityDescription.model";

export default class Program {
  constructor(program) {
    this.id = program.id || generateUUID();
    this.label = program.label || null;
    this.description = program.description || null;
    this.patientUserName = program.patientUserName;
    this.notes = new ProgramNote(program.notes);
    this.suitableForGroupTherapy = program.suitableForGroupTherapy || false;
    this.suitableForRemoteTherapy = program.suitableForRemoteTherapy || false;
    this.suitableForFreeplay = program.suitableForFreeplay || false;
    this.fromPresetId = program.fromPresetId || null;
    this.activities = program.activities
      ? program.activities.map(
          (activity) =>
            new ProgramActivity(
              activity,
              this.getActivityDescription(activity.activityId)
            )
        )
      : [];
    this.displayedColor = program.displayedColor || ["", ""]; // Default program color classes, updated by the therapy plan
  }

  getActivityDescription(activityId) {
    return ActivityDescription.query().find(activityId);
  }

  setProgramMetadata(programMetadata) {
    this.label = programMetadata.label;
    this.description = programMetadata.description;
    this.suitableForGroupTherapy = programMetadata.suitableForGroupTherapy;
    this.suitableForRemoteTherapy = programMetadata.suitableForRemoteTherapy;
    this.suitableForFreeplay = programMetadata.suitableForFreeplay;
    this.notes = new ProgramNote(programMetadata.notes);
  }

  /**
   * Getters
   */

  isCalibrationMissing(allActivities) {
    return this.activities.some((activity) => {
      return activity.isCalibrationMissing(allActivities);
    });
  }

  activityCount(allActivities) {
    let activitiesToCount = [];
    // Count only licensed activity if activity info is provided.
    if (allActivities?.length > 0) {
      activitiesToCount = this.activities.filter((act) => {
        const activityInfo = allActivities.find((a) => act.activityId === a.id);
        return activityInfo && activityInfo.isLicensedForUser;
      });
    }

    return activitiesToCount.length || 0;
  }

  // Returns the unlicensed activities from the activity list, default to returning none
  unlicensedActivities(activityDescription) {
    let unlicensedActivities = [];
    if (!activityDescription || !activityDescription.length) {
      return unlicensedActivities;
    }
    unlicensedActivities = this.activities.filter((act) => {
      let activityInfo = activityDescription.find(
        (a) => act.activityId === a.id
      );
      return activityInfo?.isLicensedForUser === false || false;
    });
    return unlicensedActivities;
  }

  programDuration(allActivities) {
    let total = this.activities.reduce((accumulator, activity) => {
      let countActivity = true;
      // Count only licensed activity if the information is available.
      if (allActivities.length) {
        let activityInfo = allActivities.find(
          (a) => activity.activityId === a.id
        );
        countActivity = activityInfo && activityInfo.isLicensedForUser;
      }
      return countActivity
        ? accumulator + activity.activitySettings.duration
        : accumulator;
    }, 0);
    return getAdaptiveTranslatedTimeFromSeconds(total, true);
  }

  containsActivitiesWithEstimatedDuration() {
    return this.activities.some((a) => a.hasEstimatedDuration);
  }

  get HasDisplayedColorsSet() {
    return (
      Array.isArray(this.displayedColor) &&
      this.displayedColor.length === 2 &&
      this.displayedColor[0] !== ""
    );
  }

  get ProgramBgColor() {
    return `bg-${this.displayedColor[0]}`;
  }

  get ProgramHoverColor() {
    return `bg-${this.displayedColor[1]}`;
  }

  get ProgramBorderColor() {
    return `border-${this.displayedColor[0]}`;
  }
}
