export default [
  {
    path: "mindpod-license",
    component: () =>
      import(
        "@/pages/EntityManagementPages/MindPodLicenseManagementPages/MindPodLicenseListPage"
      ),
  },
  {
    path: "mindpod-license/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/MindPodLicenseManagementPages/MindPodLicenseCreatePage"
      ),
  },
  {
    path: "mindpod-license/:licenseId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/MindPodLicenseManagementPages/MindPodLicenseDetailsPage"
      ),
    props: (route) => ({ licenseId: route.params.licenseId }),
  },
];
