// Data stored in the session storage
const disableTeleconsultationInStorageName = "mm_disable_teleconsultation";
const disableSideBarInStorageName = "mm_disable_sidebar";

// Disable teleconsultation
export function setSessionStorageDisableTeleconsultation(
  disableTeleconsultation
) {
  window.sessionStorage.setItem(
    disableTeleconsultationInStorageName,
    disableTeleconsultation
  );
}

export function getSessionStorageDisableTeleconsultation() {
  return (
    window.sessionStorage.getItem(disableTeleconsultationInStorageName) ===
    "true"
  );
}

// Disable sidebar
export function setSessionStorageDisableSideBar(disableSideBar) {
  window.sessionStorage.setItem(disableSideBarInStorageName, disableSideBar);
}

export function getSessionStorageDisableSideBar() {
  return window.sessionStorage.getItem(disableSideBarInStorageName) === "true";
}
