import { lowerFirst } from "lodash";
import { tr } from "@/utils/translation";

/**
 * Transform an array of string to dropdown entries.
 * @param ids [string] array of string ids to set as options
 * @param translationPrefix path to the translation key
 */
function getDropdownEntries(entries, translationPrefix) {
  const entriesTranslated = Object.values(entries).map((id) => ({
    id: id,
    name: translationPrefix ? tr(`${translationPrefix}.${lowerFirst(id)}`) : id,
  }));
  return getDropdownOptionsFromObjectArray(entriesTranslated);
}

function groupDropdownEntries(dropdownObject) {
  const entries = Object.values(dropdownObject);

  // Add indent key true to the entry within the group defined by prefix word
  let title = "";

  const entriesGrouped = entries.map((entry, index) => {
    // If we already found title in previous iterations check if we continue
    if (title && entry.id.startsWith(title)) {
      entry["indent"] = true;
      return entry;
    } else {
      title = "";
    }

    // If previous id is prefix of the current id than it's group with
    const previous = entries[index - 1]?.id.slice(0, -1);

    if (previous && entry.id.startsWith(previous)) {
      title = previous;
      entry["indent"] = true;
      return entry;
    }
    return entry;
  });

  return getDropdownOptionsFromObjectArray(entriesGrouped);
}

/**
 * Get dropdown options from object array
 * @param entries {Array} array of objects to set as options.
 * Object has the following properties
 * - id: [mandatory] is the unique key of the option
 * - name: translated name to display the option
 * - icon: icon to display as option prefix
 * - description: description to add below the option
 */
function getDropdownOptionsFromObjectArray(entries) {
  return entries.reduce((obj, entry) => {
    obj[entry.id] = entry;
    obj[entry.id]["name"] = entry.name || entry.id;
    obj[entry.id]["icon"] = entry.icon || "";
    obj[entry.id]["description"] = entry.description || "";
    obj[entry.id]["$isDisabled"] = entry.isDisabled || false;
    return obj;
  }, {});
}

export {
  getDropdownEntries,
  getDropdownOptionsFromObjectArray,
  groupDropdownEntries,
};
