import { Model } from "@vuex-orm/core";
import { isAfter, parseISO } from "date-fns";
import Profile from "@/models/Profile.model";
import { notifyError } from "@/services/api";

export const PersonalAccessTokenStatuses = Object.freeze({
  Active: "active",
  Revoked: "revoked",
  Expired: "expired",
});

export default class PersonalAccessToken extends Model {
  static entity = "personalAccessToken";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      secretToken: this.attr(null),
      addedAtUtc: this.attr(null),
      expiryDate: this.attr(null),
      revocationDate: this.attr(null),
      userName: this.hasOne(Profile, "userName"),
    };
  }

  get Status() {
    return this.revocationDate
      ? PersonalAccessTokenStatuses.Revoked
      : isAfter(parseISO(this.expiryDate), new Date())
      ? PersonalAccessTokenStatuses.Active
      : PersonalAccessTokenStatuses.Expired;
  }

  // Network functions

  static get(signal) {
    return this.api().get("/personal-access-token", {
      dataKey: "result",
      signal,
    });
  }

  static async post(newPersonalAccessToken) {
    return await this.api()
      .post("/personal-access-token", newPersonalAccessToken, {
        dataKey: "result",
      })
      .then((result) => result.response.data.result);
  }

  static async revoke(patId) {
    return await this.api().post(`/personal-access-token/revoke/${patId}`, {
      dataKey: "result",
    });
  }
}
