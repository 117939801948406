import { Model } from "@vuex-orm/core";
import Customer, { customerKinds } from "@/models/Customer.model";
import Profile from "@/models/Profile.model";
import SiteUnit from "@/models/SiteUnit.model";
import {
  SiteSAMLParameters,
  SitePasswordParameters,
} from "@/models/SiteParameters.model";
import { defaultTimezoneId } from "@/models/Timezone.model";
import { getDropdownEntries } from "@/utils/dropdown";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";
import { userApi, notifyError } from "@/services/api";

export const siteKinds = Object.freeze({
  payingCustomer: "PayingCustomer",
  household: "Household",
  productEvaluation: "ProductEvaluation",
  demo: "Demo",
  fake: "Fake",
});

export default class Site extends Model {
  static entity = "sites";

  static fields() {
    return {
      id: this.attr(null), // Primary key
      name: this.attr(null),
      kind: this.attr(null),
      samlParameters: this.attr(new SiteSAMLParameters()),
      passwordParameters: this.attr(new SitePasswordParameters()),
      customers: this.hasMany(Customer, "siteId"),
      profiles: this.hasMany(Profile, "siteId"),
      siteUnits: this.hasMany(SiteUnit, "siteId"),
      timeZoneId: this.attr(null),
    };
  }

  get EntityIdentifier() {
    return "Site";
  }

  /**
   * Returns the timezone used for the Site.
   * If no timezone is set, fallback on UTC
   * @returns {string|*}
   */
  get validTimezone() {
    return this.timeZoneId ?? defaultTimezoneId;
  }

  get PatientCustomers() {
    return this.customers.filter(
      (c) => c.kind === customerKinds.patientCustomer
    );
  }
  // customer kind other than patientCustomer
  get OtherCustomers() {
    return this.customers.filter(
      (c) => c.kind !== customerKinds.patientCustomer
    );
  }

  // Network functions

  static get(signal) {
    return this.api().get("/site", { dataKey: "result", signal });
  }

  static async getById(id) {
    return await this.api().get("/site/id/" + id, { dataKey: "result" });
  }

  static async post(newSite) {
    return this.api().post("/site", newSite, { dataKey: "result" });
  }

  static async remove(site) {
    return this.api().delete("/site/" + site.id, {
      delete: site.id,
      dataKey: "result",
    });
  }

  static async put(updatedSite) {
    // Let's strip the customer and profiles data
    let updatedSiteCopy = JSON.parse(JSON.stringify(updatedSite));
    updatedSiteCopy.customer = null;
    updatedSiteCopy.profiles = [];
    updatedSiteCopy.siteUnits = [];
    return this.api().put("/site/" + updatedSiteCopy.id, updatedSiteCopy, {
      dataKey: "result",
    });
  }

  /**
   * Get list of users that are not in any site unit
   * @param siteId
   * @returns the list of users that are not in any site unit
   */
  static async getUnassignedUsers(siteId, signal) {
    return await userApi
      .get(`site-unit-assignment/site/${siteId}/unassigned-users`, { signal })
      .then((response) => {
        return response.data.result || [];
      })
      .catch((error) => {
        notifyError(error);
        return [];
      });
  }

  /**
   * Update SAML parameters as Site manager or therapist
   * @param updatedSAMLParameters
   * Updates site data as a response and update the local storage accordingly.
   */
  static async updateSAMLParameters(updatedSAMLParameters) {
    return this.api().put("/site/saml-parameters", updatedSAMLParameters, {
      dataKey: "result",
    });
  }

  static async updatePasswordParameters(updatedParameters) {
    return this.api().put("/site/password-parameters", updatedParameters, {
      dataKey: "result",
    });
  }

  static getSiteKindList() {
    return getDropdownEntries(siteKinds, "AdminPages.Site.Kind");
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    let fields = [this.kind];
    if (this.name) {
      fields.push(tidySearchTerm(this.name));
    }
    if (this.id) {
      fields.push(this.id);
    }
    return fields;
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}
